<template>
  <static-fullscreen-card :loaded="!loading">
    <template v-slot:title> ({{ data.id }}) {{ data.name }} </template>
    <template v-slot:actions>
      <a-btn-edit v-if="id && getAccess('education.edit')" title="Редактирование" @click="editChain()" />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <portal to="v-main">
      <s-edit-form
        v-model="showEditDialog"
        v-if="showEditDialog"
        :api="url"
        :m="m"
        :id="id"
        :configForm="{ type: 'default' }"
        :init-data="{}"
        :opt="{ closeAfterSave: true, delete: true }"
        @deleted="deleted = true"
      />
    </portal>
    <v-row v-if="!loading">
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" v-if="!loading">
        <material-card color="third" class="block_detail" :style1="`height: ${detailHeight + 80}px`">
          <template v-slot:heading>
            <v-tabs dense v-model="tab" background-color="transparent" slider-color1="white" show-arrows center-active>
              <v-tab class="mr-3"> Таблица </v-tab>
              <v-tab class="mr-3"> Канбан </v-tab>
              <v-tab class="mr-3"> Эмулятор </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item>
              <TabMessages v-if="tab == 0" :data="data" :height="detailHeight" />
            </v-tab-item>
            <v-tab-item>
              <TabMessagesCanban v-if="tab == 1" :data="data" :height="detailHeight" @refresh="fitchData()" />
            </v-tab-item>
            <v-tab-item>
              <TabMessagesEmulator v-if="tab == 2" :data="data" :height="detailHeight" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </static-fullscreen-card>
</template>

<script>
import "./../components";
import { getAccess, autoHeightBlock, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, genModel],
  components: {
    TabMessages: () => import("./../tab/messages"),
    TabMessagesCanban: () => import("./../tab/messagesCanban"),
    TabMessagesEmulator: () => import("./../tab/messagesEmulator"),
  },
  props: {
    idShow: Number,
  },
  data() {
    return {
      id: 0,
      editForm: {},
      blockHeight: 150,
      loading: true,
      deleted: false,
      showEditDialog: false,
      confirmDialog: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.education.chain,
      tab: 0,
      clientHeight: 0,
    };
  },
  created() {
    this.loading = true;
    this.$root.title = "Урок";
    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
    if (this.$attrs?.tab) {
      console.log("set tab", this.$attrs);
      this.tab = parseInt(this.$attrs.tab);
    }
  },
  watch: {
    showEditDialog(v) {
      console.log("showEditDialog", this.deleted);
      if (this.deleted) {
        if (this.idShow) this.$emit("close");
        else this.$router.push({ name: this.m.routeName });
        return;
      } else if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  computed: {
    url() {
      return this.m.api;
    },
    model: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        return model;
      },
    },
    modelRight() {
      return this.calcModel("viewForm2");
    },
    modelLeft() {
      return this.calcModel("viewForm1");
    },
    tabs() {
      let tabs = [
        { name: "docs", title: "Документы", show: true },
        { name: "cashOut", title: "Расход", show: true },
        { name: "cashIn", title: "Приход", show: true },
        { name: "comments", title: "Комментарии", show: true },
        { name: "acc", title: "Проводки", show: this.getAccess("rent.accounting") },
      ];
      return tabs;
    },
  },
  methods: {
    async fitchData() {
      this.loading = !false;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = false;
    },

    updateData() {
      this.fitchData();
    },
    editChain() {
      this.editForm = { api: this.url, m: this.m, configForm: { type: "default" }, initData: {}, opt: { closeAfterSave: true } };
      this.showEditDialog = true;
    },
  },
};
</script>

<style lang="sass"></style>
